<template>
  <div :class="checkboxClass">
    <label class="checkbox">
      <input
        :id="inputLabel"
        v-bind="$attrs"
        v-model="handleInput"
        type="checkbox"
        :disabled="disabled"
        :class="['checkbox__input', errorClass]"
        :name="name || label"
        :required="required"
        :data-testid="dataTestid"
        @blur="v && v.$touch()"
      />
      <span
        class="checkbox__square"
        :class="{ 'checkbox__square--disabled': disabled }"
      />

      <span v-if="labelI18Path" :class="fontClass">
        <Translation :keypath="labelI18Path" tag="span" class="checkbox__text">
          <slot />
        </Translation>

        <span v-if="required" class="required"> *</span>
      </span>
      <span v-else :class="fontClass">
        {{ label }}
        <span v-if="required" class="required"> *</span>
      </span>
    </label>
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'
import { Translation } from 'vue-i18n'
import useInput from '@/composables/useInput'
import { formatNameForTestid } from '@/utils/forms'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  modelValue: boolean | undefined
  labelI18Path?: string
  label?: string
  name?: string
  required?: boolean
  disabled?: boolean
  v?: Validation['ExtractRulesResults']
  small?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const checkboxClass = computed(() => {
  return props.small ? 'checkbox-group--small' : 'checkbox-group'
})

const fontClass = computed(() => {
  return props.small ? 'paragraph--xs' : 'paragraph--s'
})

const dataTestid = computed(() => {
  return `${formatNameForTestid(props.name || 'consent')}_checkbox`
})

const { handleInput, errorMessage, errorClass, showErrorMessage, inputLabel } =
  useInput(props, emit)
</script>
